import React, { useState } from "react"
import PageBase from "../../components/pageBase";
import queryString from 'query-string'
import { unsubscribe } from "../../components/landing/emailAlert";

const AlertsUnsubscribePage = (props) => {
    
    console.log(props.location)
    const [feedback, setFeedback] = useState('unsubscribing ... ');

    React.useEffect(() => {
        console.log(props.location.search)

        let queries = queryString.parse(props.location.search)
        console.log(queries)

        if ('eid' in queries) {
            unsubscribe(queries['eid']).then(() => {
                setFeedback("You are now unsubscribed!");
            })
        } else {
            setFeedback("No subscriber ID found");
        }
    }, []);

    return (
        <PageBase>
            <section className="wrapper bg-light">
                <div className="py-14 pt-md-17 container">
                    <div className="row">
                        <div className="mx-auto text-center col-lg-9 col-md-10 offset-lg-2 offset-md-1">
                            <h2 className="fs-15 text-uppercase text-muted mb-3">Free Daily Credit Insights</h2>
                            <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">Unsubscribe</h3>
                            <p>{ feedback }</p>
                        </div>
                    </div>
                </div>
            </section>
        </PageBase>
      );
}

export default AlertsUnsubscribePage;